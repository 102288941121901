import React, { useEffect, useRef } from "react";
// import logo from "./logo.svg";
import chicken from "./chicken.gif";
import styles from "./App.module.css";

function App() {
    const audioRef = useRef<HTMLAudioElement>(null);

    useEffect(() => {
        window.addEventListener("click", () => {
            audioRef.current?.play();
        });

        return () => {
            window.removeEventListener("click", () => {
                audioRef.current?.pause();
            });
        };
    });

    return (
        <div className={styles.app}>
            <header className={styles.appHeader}>
                <img src={chicken} className={styles.appLogo} alt="logo" />
                {/* <img src={logo} className={styles.appLogo} alt="logo" /> */}
                <p className={styles.appText}>Hey salut tous le monde !</p>
                <audio ref={audioRef} src="/ambient_sound.mp3" autoPlay loop />
            </header>
        </div>
    );
}

export default App;
